import React, { useEffect, useState } from 'react'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { DocumentationArticleSlice, DocumentationNavigationSlice } from '../components/Slices'
import { Layout, Section, Seo } from '../components/Layout'
import { extractHeadings } from '../components/Shared/ContentTable'

deckDeckGoHighlightElement()

const Flex = styled.div`
  display: initial;
  z-index: 100;
  ${media.md`
    display: flex;
  `}
`

const DocumentationPage = ({ pageContext: { page: pageData, allPages } }) => {
  const {
    content: {
      childMarkdownRemark: { html },
    },
    documentationCategory: { slug: categorySlug, title: categoryTitle },
    slug,
    subTitle,
    title,
    faqAnswer,
  } = pageData // data.markdownRemark holds your post data
  const [content, setContent] = useState({ headings: [], html: '' })

  useEffect(() => {
    const { headings, updatedHtml } = extractHeadings(html)
    setContent({ headings, html: updatedHtml })
  }, [html])
  const faq = []
  if (faqAnswer?.faqAnswer.length) {
    faq.push({ question: title, answer: faqAnswer.faqAnswer })
  }

  const pathname = `documentation/${categorySlug}/${slug}`

  const breadcrumbList = [
    { position: 1, name: 'Documentation', path: 'documentation' },
    { position: 2, name: categoryTitle, path: `documentation/${categorySlug}` },
    { position: 3, name: title, path: pathname },
  ]

  const getStartedNodes = allPages.data.allContentfulDocumentationPage.nodes
    .filter(article => article.documentationCategory.title === "Setup Guide")

  const templateDesignNodes = allPages.data.allContentfulDocumentationPage.nodes
    .filter(article => article.documentationCategory.title === "Template Design")

  const faqNodes = allPages.data.allContentfulDocumentationPage.nodes
    .filter(article => article.documentationCategory.title === "FAQ")

  const bothCategories = [...getStartedNodes, ...templateDesignNodes]
  const index = bothCategories.findIndex((article) => slug === article.slug)
  const prev = bothCategories[index - 1]
  const next = bothCategories[index + 1]

  return (
    <Layout>
      <Seo
        title={title}
        pathname={pathname}
        breadcrumbList={breadcrumbList}
        faq={faq}
      />
      <Section>
        <Flex>
          <DocumentationNavigationSlice currentSlug={slug} getStartedArticles={getStartedNodes} templateDesignArticles={templateDesignNodes} faqArticles={faqNodes} />
          <DocumentationArticleSlice
            templateDesignSlug={`/documentation/${templateDesignNodes[0]?.documentationCategory?.slug}/${templateDesignNodes[0]?.slug}`}
            setupSlug={`/documentation/${getStartedNodes[0]?.documentationCategory?.slug}/${getStartedNodes[0]?.slug}`}
            faqSlug={`/documentation/${faqNodes[0]?.documentationCategory?.slug}/${faqNodes[0]?.slug}`}
            content={content}
            title={title}
            subTitle={subTitle}
            category={categoryTitle}
            categorySlug={categorySlug}
            prev={prev}
            next={next}
          />
        </Flex>
      </Section>
    </Layout>
  )
}

export default DocumentationPage
